import styled from "styled-components"

const PageIntro = styled.div`
  padding: 0 var(--globalPadding);
  h2 {
    font-family: var(--trim);
    font-size: 4vw;
    text-align: left;
    line-height: 1;
    color: var(--blue);
    margin: 1em 0;
    @media (max-width: 768px) {
      font-size: 7vw;
    }
    @media (min-width: 1050px) {
      font-size: 4em;
    }
  }
`

export default PageIntro
