import React from "react"
import styled from "styled-components"


export const Content = (props) => <Div>{props.children}</Div>


const Div = styled.div`
  width: 100%;
  padding: 0 var(--globalPadding) 0;
  ul,
  ol {
    padding-left: 1.25em;
  }

  .highlight {
    padding: 1em;
    background-color: #e5e5e5;
  }
`

/* .thankyou {}
    display none
    body.submit-successful &
      display block

  &-highlight
    background-color lighten($grey, 80%)
    padding 1em */

/* .mobile-only
  margin-top 2em
  display none
  ${media.}
    display flex
    justify-content center */
