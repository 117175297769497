import React from "react"
import styled from "styled-components"

export const Bound = (props) => <Div>{props.children}</Div>


const Div = styled.div`
   & *:first-child {
      margin-top: 0;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 0.5em;
      line-height: 1;
      @media (max-width: 768px) {
        font-size: 1.7em;
      }
    }

    h4 {
      color: var(--blue);
      font-size: 1.75rem;
      margin-bottom: 0.5em;
      @media (max-width: 768px) {
        font-size: 1.5em;
      }
    }

    h5 {
      font-size: 1.5rem;
      margin-bottom: 0.5em;
      @media (max-width: 768px) {
        font-size: 1.2em;
      }
    }

    h6 {
      font-size: 1.25rem;
      text-decoration: underline;
      margin-bottom: 0.5em;
      font-weight: 800;
      letter-spacing: -0.05em;
      @media (max-width: 768px) {
        font-size: 1.1em;
      }
    }

    li {
      margin-bottom: 1em;
    }

    a {
      color: var(--blue);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &:after {
        content: "\2192";
      }
    }
`