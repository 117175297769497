import React from "react"
import styled from "styled-components"

export const Col = (props) => <Div>{props.children}</Div>


const Div = styled.div`
    width: calc(50% - (var(--globalPadding) / 2));
    @media (max-width: 600px) {
      width: 100%;
    }
`