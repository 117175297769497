import React from "react"
import styled from "styled-components"



export const Row = (props) => {
  return <Div>{props.children}</Div>
}


const Div = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 600px) {
      flex-direction: column;
    }
`